.ant-table-container {
  .ant-table-content {
    .ant-table-thead {
      tr {
        .ant-table-cell {
          font-family: OpenSans-Semibold, sans-serif !important;
        }
      }
    }

    td {
      padding: 10px;
    }
  }
}