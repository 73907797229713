.ant-form {
  .ant-form-item {
    .ant-form-item-label {
      font-family: OpenSans-Semibold, sans-serif;

      label {
        color: gray !important;
      }
    }
  }
}

.label-left {
  .ant-form-item {
    .ant-form-item-label {
      text-align: left;

    }
  }
}