@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./styles/Form.scss');
@import url('./styles/PageHeader.scss');
@import url('./styles/Range.scss');
@import url('./styles/Table.scss');
@import url('./styles/Card.scss');
@import url('./styles/Description.scss');
@import url('./styles/Modal.scss');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "OpenSans", sans-serif !important;
  color: gray !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

//
.box {
  padding: 8px;
  background-color: white;

  margin: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .ant-form-item {
    margin-bottom: 0;
  }
}

//
.icon-delete {
  color: red;
  font-size: 18px;
  cursor: pointer;
}

.icon-edit {
  color: orange;
  font-size: 18px;
  cursor: pointer;
}

.icon-detail {
  color: gray;
  font-size: 18px;
  cursor: pointer;
}

.icon-switch-on {
  color: #007aff;
  font-size: 22px;
  cursor: pointer;
}

.icon-switch-off {
  color: gray;
  font-size: 22px;
  cursor: pointer;
}

//
.ant-popover-placement-bottom {
  left: 0 !important;
  width: 0px !important;

  .ant-popover-arrow-content {
    display: none;
  }

}

// FONT
.font-bold {
  font-family: OpenSans-Semibold;
}

.ant-image-preview-img-wrapper {
  transform: translate3d(0px, 0px, 0px);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.form-size {
  max-width: 768px;
  min-width: 512px;
}
